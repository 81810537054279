<template>
  <div ref="tabs_data">
    <v-card>
      <v-tabs v-model="tab" background-color="indigo" dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-if="elements.measurements">
          Signos Vitales
          <v-icon>mdi-chart-timeline-variant</v-icon>
        </v-tab>
        <v-tab v-if="elements.messaging">
          Mensajeria SME
          <v-icon>mdi-forum</v-icon>
        </v-tab>
        <v-tab v-if="elements.observations">
          Observaciones
          <v-icon>mdi-eye</v-icon>
        </v-tab>
        <v-tab v-if="elements.trackings">
          Variables Clinicas
          <v-icon>mdi-chart-box</v-icon>
        </v-tab>
        <v-tab v-if="elements.alerts">
          Alertas
          <v-icon>mdi-bell</v-icon>
        </v-tab>
        <v-tab v-if="elements.activities">
          Actividades
          <v-icon>mdi-calendar</v-icon>
        </v-tab>
        <v-tab v-if="elements.orders">
          Ordenes
          <v-icon>mdi-folder</v-icon>
        </v-tab>
        <v-tab v-if="elements.info">
          Ajustes
          <v-icon>mdi-cog-outline</v-icon>
        </v-tab>
        <!-- <v-tab v-if="elements.settings">
          Configuración
          <v-icon>mdi-cog</v-icon>
        </v-tab> -->
      </v-tabs>
      
      <v-tabs-items v-model="tab" v-if="elements.measurements">
        <!-- inicio tab mediciones -->
        <v-tab-item>
          <v-card flat class="pa-5">
            <v-row class="ma-0" justify="end">
              <v-col cols="auto" class="pt-0 pb-0">
                  <v-btn @click="permissions.get_reports ? getReport('csv') : ''" :disabled="!permissions.get_reports" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small >Descargar CSV <v-icon right dark > mdi-file-excel</v-icon> </v-btn>
              </v-col>
              <v-col cols="auto" class="pt-0 pb-0">
                  <v-btn @click="permissions.get_reports ? getReport('PDF') : ''" :disabled="!permissions.get_reports" class="pl-5 pr-5 white--text" depressed color="red accent-2" small > Descargar PDF <v-icon right dark > mdi-file-pdf-box</v-icon></v-btn>
              </v-col>
          </v-row>
            <v-row>
              <v-expansion-panels focusable class="pa-5 pt-0" multiple>
                <v-expansion-panel
                  v-for="(item, i) in listCharts"
                  :key="i"
                  @click="changeload(item)"
                >
                  <v-expansion-panel-header>
                    <div class="card-header bg-gray">
                      <h3 class="card-title ml-5">
                        <v-icon class="mr-1">{{ item.icon }}</v-icon> {{ item.name }}
                      </h3>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="12" class="card card-default shadow-sm ma-0 pa-0">
                      <v-col cols="12" class="card-body">
                        <patient-measure-chart
                          :pid="patient_id"
                          :type="item.code"
                          :load="load[`${item.initials}`]"
                        ></patient-measure-chart>
                      </v-col>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card>
        </v-tab-item>

        <!-- inicio tab mensajeria-->
        <v-tab-item v-if="elements.messaging">
          <v-card flat class="pa-5">
            <v-row>
              <v-col cols="12" class="general-calendar mt-0">
                  <MessagingPatient :patient_id="patient_id">
                   
                  </MessagingPatient>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <!-- fin tab mediciones -->
        <!-- inicio tab observaciones -->
        <v-tab-item v-if="elements.observations">
          <v-card flat class="pa-5">
            <ObservationsPatientList :patient_id="patient_id">
              
            </ObservationsPatientList>
          </v-card>
        </v-tab-item>
        <!-- fin tab observbaciones -->
        <!-- inicio tab seguimientos -->
        <v-tab-item v-if="elements.trackings">
          <v-card flat class="pa-5">
            <ClinicalObservationsPatient :patient_id="patient_id">

            </ClinicalObservationsPatient>
          </v-card>
        </v-tab-item>
        <!-- fin tab seguimientos -->

        <!-- inicio tab alertas -->
        <v-tab-item @change="reloadalerts = isActive" v-if="elements.alerts">
          <v-card flat>
            <v-card-text>
              <Alerts :reload="reloadalerts"></Alerts>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- fin tab alertas -->

        <!-- inicio tab actividades -->
        <v-tab-item v-if="elements.activities">
          <v-card flat class="pa-5">
            <v-col cols="12" class="mt-5">
              <v-row>
                <v-col cols="12" class="general-calendar mt-0">
                  <CalendarEncounter
                    type="patient"
                    :patient_id="order.patient_id"
                    :timeZone="order.patient_timeZone"
                  ></CalendarEncounter>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-tab-item>
        <!-- inicio tab actividades -->

        <!-- incio tab ordenes -->
        <v-tab-item v-if="elements.orders">
          <v-card flat class="pa-5">
            <OrdersTab :statusPatient="order.status"></OrdersTab>
          </v-card>
        </v-tab-item>
        <!-- fin tab ordenes -->


        <!-- inicio tab más información -->
        <v-tab-item v-if="elements.info">
          <v-card flat class="pa-5">
            <MoreData></MoreData>
          </v-card>
        </v-tab-item>
        <!-- fin tab más información -->

        <!-- incio tab configuración -->
        <!-- <v-tab-item v-if="elements.settings">
          <v-card flat class="pa-5">
            <SettingsTab></SettingsTab>
          </v-card>
        </v-tab-item> -->
        <!-- fin tab configuración -->
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import patientMeasureChart from "@/components/charts/patientMeasureChart.vue";
import CalendarEncounter from "@/components/views/CalendarEncounter.vue";
import MoreData from "@/components/views/MoreData.vue";
import Alerts from "@/components/views/AlertsTab.vue";
import ObservationsPatientList from "@/components/views/ObservationsPatientList.vue";
import ClinicalObservationsPatient from "@/components/views/ClinicalObservationsPatient.vue";
import OrdersTab from "./OrdersTab.vue";
import MessagingPatient from "@/components/views/MessagingPatient.vue";
// import SettingsTab from "@/components/views/SettingsTab.vue"
import AxiosServices from "@/services/AxiosServices";
import axios from "axios";
export default {
  data() {
    return {
      elements: {
        measurements: true,
        messaging: false,
        observations: true,
        trackings: true,
        vaccines: false,
        medicines: true,
        activities: true,
        orders: true,
        realiced: true,
        alerts: true,
        info: true,
        settings:true,
      },
      permissions:{},
      tab: null,
      reveal: false,
      reloadalerts: false,
      data_vaccines: [],
      orderlist: [],
      listCharts: [
        { name: "Frecuencia Cardiaca", initials: "fc", icon: "mdi-heart-pulse", code: 1 },
        { name: "Oximetría", initials: "spo2", icon: "mdi-lungs", code: 2 },
        { name: "Presión Arterial", initials: "presion", icon: "mdi-pulse", code: 3 },
        { name: "Glucosa", initials: "glucometria", icon: "mdi-water", code: 6 },
        { name: "Temperatura", initials: "temp", icon: "mdi-thermometer", code: 7 },
        { name: "Peso", initials: "peso", icon: "mdi-scale-bathroom", code: 5 },
      ],
      on: false,
      dialog_encounter: false,
      dialog_order: false,
      load: {
        fc: false,
        spo2: false,
        presion: false,
        glucometria: false,
        temp: false,
        peso: false,
      },
      order: {
        patient_id: '',
        status: null,
        client_id: this.$store.state.user.client_id,
        patient_timeZone: ''
      },
      url_csv:'',
      url_pdf:''
    };
  },
  props: {
    patient_id: {
      default: "",
    },
    pid: String,
  },
  methods: {
    createOrder: function () {
      console.log("holallalalal");
    },
    createEncounter: function () {
      console.log("inter");
      axios
        .post(this.$URL + "api/v1/patients/create_Encounter_vacunation", {
          patient_id: this.$route.params.id,
          client_id: this.$store.state.user.client_id,
          user_id: this.$store.state.user.user_id,
        })
        .then((res) => {
          console.log("crea o no crea trae o no el id", res.data);
          this.$router.push({ name: "encounters", params: { id: res.data } });
        });
    },
    getTimeZone: function (){
            AxiosServices.patientDetail(this.$route.params.id).then(res => {
              this.order.patient_timeZone = res.data.data.time_zone
              this.order.patient_id = res.data.data.id
              this.order.status = res.data.data.status
              this.permissions=res.data.data.permissions
            }).catch(error => {
              console.log('el error es', error);
            })
          },
    get_encounter_vaccines: function () {
      console.log("entro!", this.$route.params.id);
      axios
        .get(
          this.$URL + "api/v1/patients/" + this.$route.params.id + ".json",
          this.$store.getters.header
        )
        .then((res) => {
          this.data_vaccines = res.data.encounters;
        });
    },
    changeload: function (item) {
      this.load[`${item.initials}`] = !this.load[`${item.initials}`];
    },
    getReport: function(type){
        if (type == 'PDF') {
            this.getPDF()
        } else {
            this.getCSV()
        }
    },
    getPDF: function(){
        AxiosServices.patientExportPDF(this.$route.params.id).then( res => {
            this.url_pdf = res.data.data.url
            window.open(this.url_pdf, '_blank');
        }).catch(error => {
            console.log('el error en traer pdf es:',error);
        })
    },
    getCSV: function(){
        AxiosServices.patientExportCSV(this.$route.params.id).then( res => {
            console.log('trae:',res.data);
            this.url_csv = res.data.data.url
            window.open(this.url_csv, '_blank');
        }).catch(error => {
            console.log('el error en traer csv es:',error);
        })
    },
    alertsTab: function() {
      this.tab=2
      const sectionElement = this.$refs.tabs_data;
      sectionElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

    },
  },
  mounted() {
    this.$store.dispatch("reseterror");
    this.getTimeZone()
  },
  components: {
    CalendarEncounter,
    MoreData,
    patientMeasureChart,
    Alerts,
    OrdersTab,
    ObservationsPatientList,
    ClinicalObservationsPatient,
    MessagingPatient
  },
};
</script>

<style>
.general-calendar {
  margin-top: -50px;
}
.v-expansion-panel-content__wrap {
  padding: 0 5px 5px;
}
@media (max-width: 960px) {
  ._order-1 {
    order: 1;
    padding-bottom: 10px;
  }
  ._order-2 {
    order: 2;
  }
  .general-calendar {
    margin-top: 10px;
  }
}
</style>
