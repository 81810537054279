<template>
  <div>
    <TitleView title="Pacientes"></TitleView>
    <div v-if="query != undefined && patientlist.length == 0">
      <v-col class="d-flex align-center flex-column message-no-data">
        <v-icon size="100">mdi-account-search</v-icon>
        <h1>No se encontraron resultados</h1>
        <router-link to="/patients" class="mt-5">
          <v-btn color="indigo" class="white--text" small> Búsqueda avanzada <v-icon class="white--text ml-3" small>mdi-arrow-right-thick</v-icon></v-btn>
        </router-link>
      </v-col>
    </div>
    <div v-else>
      <div v-if="query">

        <PatientMiniCard v-for="pat_info in patientlist" :key="pat_info.id"  :patient_info="pat_info"></PatientMiniCard>
      </div>
      <div v-else>
        <v-card>
          <v-card-text>
            <v-tabs v-model="activeTab" color="indigo" class="mb-4">
              <v-tab :value="0">
                Activos
              </v-tab>
              <v-tab :value="1">
                Inactivos
              </v-tab>
            </v-tabs>
            <v-row class="mt-4 mb-4 d-flex justify-center">
              <v-col v-if="filtersType['tags']['active']" cols="12" sm="8" md="9" class="pt-0 pb-0">
                <SelectSearch                                    
                  label="Etiquetas" 
                  model="tags" 
                  v-model="filter.tags" 
                  :multiple="true"
                  outlined="outlined" filter="filter"
                  :valuex="filter.tags == '' ? 1 : { id: filter.tags, text: '' }">
                </SelectSearch>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="pt-0 pb-0">
                <v-btn @click="filterPatients" :disabled="disableFilter" block color="indigo lighten-4 indigo--text text--darken-4">
                  <v-icon left>mdi-filter</v-icon> Filtrar Etiquetas
                </v-btn>
              </v-col>             
            </v-row>

            <v-data-table 
            :headers="headers"
            :items="patientlist"
            :loading="spinnerClosed"
            :search="search"
            :sort-by="['status']"
            :sort-desc="[true]"
            :server-items-length="totalItems"
            :options.sync="options"
            :items-per-page="20"
            :items-per-page-options="[20, 50, 100]"
            :footer-props="{
              'items-per-page-options': [20, 50, 100],
              'items-per-page-text': 'Filas por página:'
            }"
            class="elevation-1 GdiTable"
            item-key="id">
              <template v-slot:[`item.status`]="{ item }">
                <v-chip class="pa-0 pl-3 pr-3" :color="colorestado(item.status)" small outlined>{{item.status_name}}</v-chip>
              </template>
              <template v-slot:[`item.gateway`]="{ item }" >
                <div style="width:40px">
                  <v-icon :color="item.gateway.length >= 1 || item.card_id.length >= 1 ? 'success' : 'grey lighten-2'">mdi-{{item.gateway.length >= 1 || item.card_id.length >= 1 ? 'router-wireless' : 'router-wireless-off'}}</v-icon>
                  <v-icon v-show="item.card_id!=''" class="mult-gateway" color="green darken-4" size="12px">mdi-account-multiple</v-icon>
                </div>
              </template>
              <template v-slot:[`item.first_name`]="{ item }">
                <span class="capitalize color-text-glya"><b>{{item.first_name}} {{item.last_name}}</b></span>
              </template>
              <template v-slot:[`item.nid`]="{ item }">
                <span>{{item.id_type_name}} {{item.nid}}</span>
              </template>
              <template v-slot:[`item.age`]="{ item }">
                <span>{{item.age}}  Años</span>
              </template>
              <template v-slot:[`item.cellphone`]="{ item }">
                <span v-if="item.cellphone == '' || item.cellphone == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.cellphone }}</span>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <span v-if="item.email == '' || item.email == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.email }}</span>
              </template>
              <!-- <template v-slot:[`item.address`]="{ item }">
                <span v-if="item.address == '' || item.address == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.address}}</span>
              </template> -->
              <template v-slot:[`item.show`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <router-link :to="`patients/${item.id}`">
                      <v-btn  v-bind="attrs" v-on="on" color="indigo lighten-1" outlined x-small> <v-icon small>mdi-eye</v-icon>{{item.none}}</v-btn>
                    </router-link>
                  </template>
                  <span>Ver Paciente</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    
  </div>
</template>

<script>
import TitleView from '@/components/views/TitleView.vue'
import AxiosServices from '@/services/AxiosServices';
//import BaseInput from '@/components/forms_items/BaseInput';
import PatientMiniCard from '@/components/views/PatientMiniCard.vue'
import SelectSearch from '../forms_items/SelectSearch.vue';

export default {
  name: 'PatientsIndex',
  mounted(){
    this.getpatients()
  },
  data(){
    return {
      activeTab: 0,
      query:this.$route.query.q,
      patientlist:[],
      spinner:false,
      spinnerClosed:false,
      search: '',
      totalItems: 0,
      per_page: 20,
      page: 1,
      filter:{
        tags:'',
      },
      headers: [
                { text: "Estado", value: "status" },
                { text: "Glady", value: "gateway" },
                { text: "Nombre", value: "first_name" },
                { text: "N° Documento", value: "nid" },
                { text: "Edad", value: "age" },
                { text: "Teléfono", value: "cellphone"},
                { text: "E-mail", value: "email" },
                { text: "", value: "show" }
            ],
            filtersType:{
                'tags':{'active':true, 'label':'Etiquetas', 'filter':['tags']},
              },
      showBtnFilter: ['tags'],
      options: {},
    }
  },
  methods:{
    updatePage(page){
            this.page = page
            this.getpatients()
        },
        updateItemsPerPage(per_page){
            this.per_page = per_page
            this.page = 1
            this.getpatients()
        },

    getpatients:function(){      
      this.spinnerClosed = true
      if (this.query == '' || this.query == undefined) {
        this.filterPatients()
      } else {
        AxiosServices.patientSearch(this.query)
        .then(res => {
          this.patientlist = res.data.data.data;
        })
        .catch(error => {
          console.log('el error es ', error);
          // this.$store.dispatch("addNotification", error.response.data)
        })
        .finally(() => {
          this.spinnerClosed = false
        })
      }
    },
    filterPatients:function(){
      let params = this.buildParams()
      this.spinnerClosed = true
      AxiosServices.patientList(params)
      .then(res => {
        this.patientlist = res.data.data.data
        this.totalItems = res.data.data.total
        this.page = res.data.data.current_page || 1
      }).catch(error => {
        console.log('el error filtrando es ', error);
      })
      .finally(() => {
        this.spinnerClosed = false
      })
    },
    buildParams:function () {
        let params = {'page': this.page, 'per_page': this.per_page}
        params['status'] = this.activeTab === 0 ? 1 : 0
        
        if (this.showBtnFilter && this.showBtnFilter.length > 0) {
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] != '' || this.filter[field]==0){
                  params[field] = this.filter[field].toString()               
                }
            });
        }
        
        return params
    },
    colorestado: function (estado) {
      switch (estado) {
      case 0:
        return "grey";
      case 1:
        return "success";
      case 2:
        return "red";
      case 3:
        return "black";
      }
    },
  },
  computed:{
    disableFilter() {
      return this.filter.tags === '' || this.filter.tags === null;
    }
  },

  watch:{
    activeTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterPatients()
      }
    },
    $route() {
      this.query=this.$route.query.q
      this.getpatients()
    },
    options: {
      handler() {
        this.page = this.options.page
        this.per_page = this.options.itemsPerPage
        this.filterPatients()
      },
      deep: true
    },
  },
  components: {
    TitleView,
    PatientMiniCard,
    SelectSearch
  }
}
</script>
<style>
  .message-no-data .v-icon, .message-no-data h1, .message-no-data h2, .message-no-data h3, .message-no-data h4, .message-no-data h5 {
    color: rgb(195, 195, 195);
  }
</style>