<template>
  <v-container class="pa-2">
    <v-row>
        <v-col class="pa-0"> <h2 class="pt-4">Variables Clinicas</h2></v-col>
        <v-col cols="12">
          <div v-if="clinical_observations.length === 0" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <p class="mt-2">Cargando variables clínicas...</p>
          </div>
          
          <div v-else>
            <v-card v-for="observation in clinical_observations" :key="observation.id" class="mb-4">
              <v-card-title class="primary white--text">
                {{ observation.name }}
                <v-chip class="ml-2" small>{{ observation.loinc_code }}</v-chip>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <!-- Tabla en la mitad izquierda -->
                  <v-col cols="12" md="6">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Fecha</th>
                            <th class="text-left">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="obs in observation.observations" :key="obs.id">
                            <td>{{ obs.formated_date }}</td>
                            <td>{{ obs.value }} <span v-if="observation.unit" class="unit-text">{{ observation.unit }}</span></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  
                  <!-- Gráfico en la mitad derecha -->
                  <v-col cols="12" md="6" class="d-flex align-center justify-center">
                    <div v-if="observation.observations.length > 0" class="chart-container">
                      <img :src="generateChartUrl(observation)" alt="Gráfico de tendencia" class="chart-image" />
                    </div>
                    <div v-else class="text-center">
                      <p class="text-subtitle-1">No hay suficientes datos para mostrar un gráfico</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AxiosServices from '@/services/AxiosServices'
export default {
  props: {
    patient_id: {
      type: String,
      required: true,
    },
  },
  data(){
    return {
      clinical_observations: [],
    }
  },
  mounted(){
    this.getClinicalObservations()
  },
  methods: {
    getClinicalObservations(){
      AxiosServices.patientGetClinicalObservations(this.patient_id).then(response => {
        this.clinical_observations = response.data.data
      }).catch(error => {
        console.error('Error al obtener variables clínicas:', error)
      })
    },
    generateChartUrl(observation) {
      // Extraer datos para el gráfico
      const labels = observation.observations.map(obs => obs.formated_date);
      const values = observation.observations.map(obs => obs.value);
      
      // Si solo hay un punto, crear etiquetas para centrar el punto
      let finalLabels = labels;
      let finalValues = values;
      
      if (labels.length === 1) {
        // Crear etiquetas vacías antes y después para centrar el punto
        finalLabels = ['', labels[0], ''];
        finalValues = [null, values[0], null];
      }
      
      // Configuración del gráfico
      const chartConfig = {
        type: 'line',
        data: {
          labels: finalLabels,
          datasets: [{
            label: observation.name,
            data: finalValues,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            pointRadius: 2,
            pointHoverRadius: 3,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          layout: {
            padding: {
              left: 5,
              right: 5,
              top: 5,
              bottom: 10
            }
          },
          scales: {
            xAxes: [{
              display: true,
              gridLines: {
                drawOnChartArea: false,
                color: "rgba(0, 0, 0, 0)",
                drawBorder: false,
                zeroLineWidth: 0
              },
              scaleLabel: {
                display: false
              },
              ticks: {
                fontSize: 6,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                drawOnChartArea: true,
                color: "#DDD",
                zeroLineColor: "#BBBBBB",
                drawBorder: true,
                borderDash: [],
                lineWidth: 0.5,
                tickMarkLength: 3
              },
              scaleLabel: {
                display: true,
                labelString: observation.unit || '',
                fontSize: 6
              },
              ticks: {
                fontSize: 6
              },
              position: 'left'
            }]
          },
          elements: {
            line: {
              borderWidth: 1
            },
            point: {
              radius: 2,
              hitRadius: 3
            }
          }
        }
      };
      
      // Codificar la configuración para la URL
      const encodedConfig = encodeURIComponent(JSON.stringify(chartConfig));
      
      // Retornar la URL de QuickChart con tamaño aún más reducido
      return `https://qc.glya.co/chart?c=${encodedConfig}&w=250&h=150`;
    }
  }
}
</script>

<style scoped>
.v-card-title {
  font-weight: 400;
  font-size: 1rem;
}

.v-card {
  border-radius: 8px;
  overflow: hidden;
}

.primary {
  padding: 8px 16px;
}

.chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-left: 15px;
}

.chart-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.unit-text {
  color: #666;
  font-size: 0.85em;
  margin-left: 3px;
}
</style>