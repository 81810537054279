<template>
<v-container fluid>
    <v-row>
        <!-- Columna de Configuración y Nuevo Mensaje -->
        <v-col cols="12" md="6">
            <v-card class="mb-4">
                <v-card-title>
                    <v-icon left>mdi-cog</v-icon>
                    Configuración Glya SME
                </v-card-title>
                <v-card-text>
                    <template v-if="!sme_info.sme_enabled">
                        <v-alert type="error" dense text>El paciente no esta registrado en SME</v-alert>
                        <v-btn color="success" block @click="registerSme" class="mt-2">   
                            Registrar Paciente en SME
                        </v-btn>
                    </template>
                    <template v-else>
                        <b>SME ID:</b> {{ sme_info.sme_id }}
                        <v-btn color="success" block @click="updateMetadata" class="mt-2">Actualizar Metadatos SME</v-btn>
                    </template>
                </v-card-text>
            </v-card>

            <v-card class="mb-4">
                <v-card-title>
                    <v-icon left>mdi-message-plus</v-icon>
                    Mensaje de Bienvenida
                </v-card-title>
                <v-card-text>
                    <v-btn
                        color="success"
                        block
                        @click="sendWelcomeMessage"
                        class="mt-2"
                    >
                        <v-icon left>mdi-hand-wave</v-icon>
                        Enviar Mensaje de Bienvenida
                    </v-btn>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-title>
                    <v-icon left>mdi-message-text</v-icon>
                    Nuevo Mensaje
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-textarea
                            v-model="newMessage"
                            label="Mensaje"
                            outlined
                            auto-grow
                            rows="4"
                        ></v-textarea>
                        <v-btn
                            color="primary"
                            block
                            @click="sendMessage"
                            class="mt-4"
                        >
                            <v-icon left>mdi-send</v-icon>
                            Enviar
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- Columna de Mensajes Recientes -->
        <v-col cols="12" md="6">
            <v-card height="100%">
                <v-card-title>
                    <v-icon left>mdi-whatsapp</v-icon>
                    Mensajes Recientes
                </v-card-title>
                <v-list two-line>
                    <v-list-item
                        v-for="(chat, index) in recentChats"
                        :key="index"
                        @click="selectChat(index)"
                    >
                        <v-list-item-avatar>
                            <v-img :src="chat.avatar"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ chat.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ chat.lastMessage }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-action-text>{{ chat.lastMessageTime }}</v-list-item-action-text>
                            <v-badge
                                v-if="chat.unreadCount"
                                :content="chat.unreadCount"
                                color="green"
                                dot
                            ></v-badge>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import AxiosServices from "@/services/AxiosServices";
export default {
    name: 'MessagingPatient',
    props: {
        patient_id: {
            type: Number,
            required: true
        }
    },
    mounted(){
        this.getSmeInfo()
    },
    data() {
        return {
            recentChats: [],
            selectedChat: null,
            messages: [],
            newMessage: '',
            sme_info: {sme_enabled:false,sme_id:null,sme_metadata:{}},
            patient: this.patient_id,
            masa: this,
        };
    },
    methods: {
        registerSme(){
            AxiosServices.patientSmeRegister(this.patient_id).then(res => {
                this.sme_info = res.data.data
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
            })
        },
        updateMetadata(){
            AxiosServices.patientSmeUpdateMetadata(this.patient_id, this.sme_info.sme_metadata).then(res => {
                this.sme_info = res.data.data
            })
        },
        prueba(){
            console.log("prueba22",this.sme_info)
        },
        getSmeInfo(){
            AxiosServices.patientSmeInfo(this.patient_id).then(res => {
                this.sme_info = res.data.data
            })
        },
        sendWelcomeMessage() {
            AxiosServices.patientSmeLaunchScript(this.patient_id, 'welcome_message', {}).then(res => {
                this.$store.dispatch("addNotification", res.data);
            }).catch(error => {
                this.$store.dispatch("addNotification", error.response.data);
            })
        },
        sendMessage() {
            // Aquí puedes implementar la lógica para enviar un nuevo mensaje
            console.log('Nuevo mensaje enviado:', this.newMessage);
        }
    },
    computed: {
    },
    created() {
    }
};
</script>

<style scoped>
        /* Estilos generales del chat */
        .v-list {
            background-color: #efeae2;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUSSURBVGiB7ZlrbBRVFMd/Z3Zmd9vdthTaWiy2PJZHC6VUKgRqCkJBEkChhABBEgwYwUBCCCZGTUw0PBIiGPwkPj4QNAqJGpUPYqQayJYCRQyPloYKLZS+tvQB3e3uzO7MHT+UwO7O7M7s0m7E+H99zT3n3PP/z713zr0DYcKECRMmzP8EupQgyRYVvQ4VtQpNyUdTslGUFBDxoDrQXG1o7ltoag+KuwVN6QQtABpg0dkxGDUYjLEYTYkYTYkYTBZMZiuqxw2aiqp4UVU3quJB8XnxeZ0EfE58Xgd+rwO/14Xf50D1u9HUAJqq/FOiqgE0NQiaiub3oLpdKAEXasCD5nWhBjxofg9qwIemBEAJoKlBVL8b1e9GDXhQ/R40vxdN9aMGvKgBN5rfixrwogV9qAEPasCL5g+tX1NDa1VVFVXxoqoKqhJAVXyoih9VVdBUFVX1o6oKmuJHU1VUNYCqqGhqEE1VURQ/mhpEVQNoqoKmKqiqgqYG0VQFTVVQlQCaqqAoATRNRVUDqIofVVHQVAVNVVGVIKoSQFMVVEVBVQNoqoKmhtaoqQE0NRQz9K6qqEoANeBFDXjQ/F5UvwfV70FTQn3/jc/rJOB1EvA5Q0YXVUE7nkdw0yRUhwXNbQHAYPVhyuxmWPVpDNYAepcHvdONzuFGdbjQOzzoHS50Tg96pwudw43O6UHvcKN3utE7POgdLvROD3qnB73Djc7hQe90o3d40DvdoT6HG73Djc7hQe90o3e40bs8IS2HG73Lg87hQe90o3d40Ls8IS2nG53Djc7hQe90o3O40bs8IS2nB53Djc7hxmC0YjRZMFsSMZoSMZoSMBjNGM0WjEYLRrMFg8mM0WTBaLZgMFkwmCwYTRaMJjNGkwWD2YLRZMFgNmM0WTCYLRhMZgwmC0azGYPJgtFsxmCyYDSbMZjMGE1mDGYLBpMZo8mM0WTBaLZgNFswmiwYTWaMJjNGkwWD2YLBZMFoNmM0WTCYLRhNZowmC0azBYPJjNFkwWCyYDCZMZotGEwWjGYzRpMFg8mM0WTBaLZgMJkxmiwYTBaMJjNGkwWjyYLBZMZosmA0mzGYLBhNFgwmM0azBYPJgtFsxmiyYDCZMZrMGM0WjGYLRpMZg8mC0WzGaLJgMJkxmiwYTWaMJgtGkwWDyYzRbMFosmAwmTGaLRhNFgwmM0azBYPJgtFkwWg2YzBZMJotGE1mDCYLRrMFo8mM0WTBYDJjNFswmiwYTWaMZgsGkxmjyYLRbMFgsmA0mzGYLBhNFoxmMwaTBaPZgtFkwWCyYDRbMJrMGEwWjGYLRpMZg8mC0WzBaLZgMJkxmiwYTWaMJgtGswWDyYzRbMFosmAwmTGaLRhNFgwmM0azBaPZgsFkxmiyYDSbMZgsGM0WjCYzBpMFo9mC0WTGYLJgNFswmswYTBaMZgtGkwWDyYzRbMFosmAwmTGaLRhNFgwmC0azGYPJgtFswWgyYzBZMJotGE1mDCYLRrMFo8mMwWTBaLZgNJkxmCwYzRaMJjNGkwWD2YLRbMFgMmM0WTCaLRhNZgwmC0azBaPJjMFkwWi2YDSZMZgsGM0WjCYzBpMFo9mC0WTGYLJgNFswmswYTBaMZgtGkxmDyYLRbMFoMmMwWTCaLRhNZgwmC/8AomuPp3xnUyIAAAAASUVORK5CYII=");
        }

        .v-list-item {
            border-bottom: 1px solid #e9edef;
            cursor: pointer;
            transition: background-color 0.2s;
        }

        .v-list-item:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .v-list-item-content {
            padding: 12px 0;
        }

        .v-list-item-title {
            color: #111b21;
            font-size: 16px;
            font-weight: 500;
        }

        .v-list-item-subtitle {
            color: #667781;
            font-size: 14px;
            margin-top: 2px;
        }

        .v-list-item-action-text {
            color: #667781;
            font-size: 12px;
        }

        .v-badge {
            background-color: #25d366;
            color: white;
            font-size: 12px;
            padding: 4px 8px;
            border-radius: 12px;
        }

        .v-list-item-avatar {
            margin: 8px 15px 8px 13px;
        }

        .v-img {
            border-radius: 50%;
        }

        .v-card {
            border-radius: 0;
            box-shadow: 0 1px 3px rgba(11, 20, 26, 0.08);
        }

        /* Estilos para mensajes entrantes */
        .message-received {
            background-color: #ffffff;
            border-radius: 7.5px;
            padding: 6px 7px 8px 9px;
            margin: 8px;
            max-width: 65%;
            float: left;
            position: relative;
            box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
        }

        .message-received::before {
            content: "";
            position: absolute;
            top: 0;
            left: -8px;
            width: 0;
            height: 0;
            border-top: 8px solid #ffffff;
            border-left: 8px solid transparent;
        }

        /* Estilos para mensajes salientes */
        .message-sent {
            background-color: #d9fdd3;
            border-radius: 7.5px;
            padding: 6px 7px 8px 9px;
            margin: 8px;
            max-width: 65%;
            float: right;
            position: relative;
            box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
        }

        .message-sent::before {
            content: "";
            position: absolute;
            top: 0;
            right: -8px;
            width: 0;
            height: 0;
            border-top: 8px solid #d9fdd3;
            border-right: 8px solid transparent;
        }

        /* Contenedor de mensajes */
        .messages-container {
            padding: 20px;
            overflow-y: auto;
            height: calc(100vh - 120px);
        }

        /* Tiempo de mensaje */
        .message-time {
            font-size: 11px;
            color: #667781;
            float: right;
            margin-left: 8px;
            margin-top: 2px;
        }

        /* Estado de mensaje */
        .message-status {
            float: right;
            margin-left: 4px;
            color: #667781;
            font-size: 14px;
        }


</style>