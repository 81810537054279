<template>
  <v-container >
    <div>
      <v-row>
        <v-col cols="6">
          <v-card >
            <v-card-title  class="yellow darken-3 pt-2 pb-0 white--text">
              <v-icon size="30" class="pb-2 pr-3" dark>mdi-bell</v-icon>Mis Alertas asignadas ({{ totalMyAssigned }})
            </v-card-title>
            <v-card-text class="pt-3">
              <v-hover v-slot="{ hover }">
                <div @click="goToMyAlerts" class="cursor-pointer" :class="{ 'elevation-4': hover }">
                  <div v-if="totalMyAssigned > 0">
                    <v-col class="d-flex align-center flex-column message-no-data pa-4">
                      <v-icon size="60" color="amber darken-2">mdi-bell-alert</v-icon>
                      <h3>Tienes {{ totalMyAssigned }} alertas por gestionar</h3>
                      <v-btn color="amber darken-2" text class="mt-2">
                        <v-icon left>mdi-arrow-right</v-icon>Ver mis alertas
                      </v-btn>
                    </v-col>
                  </div>
                  <div v-else>
                    <v-col class="d-flex align-center flex-column message-no-data pa-4">
                      <v-icon size="60">mdi-bell</v-icon>
                      <h3>No tiene Alertas por gestionar</h3>
                      <v-btn color="grey" text class="mt-2">
                        <v-icon left>mdi-arrow-right</v-icon>Ir al gestor de alertas
                      </v-btn>
                    </v-col>
                  </div>
                </div>
              </v-hover>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="6">
          <v-card class="autoheight"> 
            <v-card-text>
              <h2>Alertas Abiertas</h2>
              <alerts-total-chart></alerts-total-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <metabase-dash></metabase-dash>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
//import riskChart from "@/components/charts/riskChart.vue";
import metabaseDash from "@/components/primaries/metabaseDash.vue";
//import complianceChart from "@/components/charts/complianceChart.vue";
import alertsTotalChart from "@/components/charts/alertsTotal.vue";
//import alertsPerUsers from "@/components/charts/alertsPerUsers.vue";
//import CardAlert from '../views/CardAlert.vue';
import AxiosServices from "@/services/AxiosServices";


export default {
  name: "MainPage",
  data() {
    return {
      client_id: "prueba",
      tab: null,
      tag_id:'none',
      tab2: null,
      alerts_count:0,
      start_at:new Date().toISOString().split('T')[0],
      end_at:new Date().toISOString().split('T')[0],
      showPersonalized:false,
      alerts_assigned:[],
      totalMyAssigned: 0,
    };
  },
  mounted () {
    this.getAlerts();
    this.setLastMonth();
    this.getOpenAlerts()
    this.getMyAssignedAlertsCount();
  },
  methods: {
    getOpenAlerts:function(){
            AxiosServices.clientAlertsList(this.$store.state.user.client_id,0)
            .then(res => {
                this.filterAndDivideAlerts(res.data.data.data)
            }).catch(error => {
                console.log('el error es',error);
            })
        },
    filterAndDivideAlerts:function(data){
      this.alerts_assigned=data.filter((alert) => alert.user_id == this.$store.state.user.id)
        },
    setToday:function(){
      this.start_at=new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]
      this.end_at=new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]
      this.showPersonalized=false
    },
    setLast7days:function(){
      this.start_at=new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('es-ES', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).split('/').reverse().map((value, index) => index === 1 ? value.padStart(2, '0') : value).join('-')
      this.showPersonalized=false
    },
    setLastMonth:function(){
      this.start_at=new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString('es-ES', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).split('/').reverse().map((value, index) => index === 1 ? value.padStart(2, '0') : value).join('-')
      this.showPersonalized=false
    },
    setPersonalized:function(){
    

    },   
    test() {
      var axios = require("axios").default;
      console.log("User_id: ", localStorage.getItem("user_id"));
      console.log("User:", localStorage.getItem("user"));
      var options = {
        method: "GET",
        url: this.$URL + "reports",
        params: { user_id: localStorage.getItem("user_id") },
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
          user_id: localStorage.getItem("user_id"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log("Data retrived", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    sendResponse: function () {
      var response = { success: false, message: "esto es una pinche prueba" }; //cambiando el true por false cambia el color :) 👍 😀

      this.$store.dispatch("addNotification", response);
    },
    getAlerts(){
      AxiosServices.clientAlertsList(this.$store.state.user.client_id,0)
        .then(res => {
          this.alerts_count=res.data.data.length            
        }).catch(error => {
            console.log('el error es',error);
        })
    },
    goToMyAlerts:function(){  
        this.$router.push({name:"alerts_management", params: { activeTab: 'tab-1', activeSubTab: 'subtab-2' }})    
    },
    getMyAssignedAlertsCount() {
        const params = {
            client_id: this.$store.state.user.client_id,
            filter: 1,  // alertas asignadas al usuario actual
            user_id: this.$store.state.user.id,
            per_page: 1  // Solo necesitamos el total, no los datos
        };
        
        AxiosServices.clientAlertsList(params)
        .then(res => {
            this.totalMyAssigned = res.data.data.total;
        }).catch(error => {
            console.log('Error al obtener el total de alertas asignadas:', error);
        });
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "MainLayout");
  },
  computed: {
    user:function(){
      return this.$store.state.user
    }
  },
  components: {
    //riskChart, 
    //complianceChart, 
    alertsTotalChart, 
    //alertsPerUsers, 
    metabaseDash
  },
};
</script>
<style>
  .line-height-1{
    line-height: 1rem;
  }
  .estado{
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    border-radius: 5px 0px 0px 0px;
  }
  .estado-gest{
    background-color: blue;
  }
  .autoheight{
    height: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
    transition: all 0.3s;
  }
</style>
