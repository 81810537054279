import AxiosServices from '@/services/AxiosServices'

const state = {
    patient: {}
}

const getters = {
    patient: state => state.patient
}

const mutations = {
    SET_PATIENT(state, patient) {
        state.patient = patient
    }
}

const actions = {
    getPatient(context, patient_id) {
        AxiosServices.patientDetail(patient_id).then(res => {
            console.log("STORE get patient",res)
            context.commit('SET_PATIENT', res.data.data)
        }).catch(error => {
            console.log('el error es', error);
        })
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}   